import { fcaApiSlice } from './fcaApiSlice';
export const apiSlice = fcaApiSlice.enhanceEndpoints({
  endpoints: {
    loginUser: (endpoint) => {
      endpoint.transformResponse = (response, meta) => ({
        response,
        has2fa: meta.response.headers.get('x-2fa-configured') == 'true',
      });

      endpoint.transformErrorResponse = (response, meta) => ({
        response,
        has2fa: meta.response.headers.get('x-2fa-configured') == 'true',
      });
    },

    getAllAnalyses: (endpoint) => {
      endpoint.transformResponse = (response, meta) => ({
        analyses: response,
        total_count: meta.response.headers.get('x-total-count'),
      });
    },

    getAllTasks: (endpoint) => {
      endpoint.transformResponse = (response, meta) => ({
        tasks: response,
        totalCount: meta.response.headers.get('x-total-count'),
      });
    },

    getAllProducts: (endpoint) => {
      endpoint.transformResponse = (response, meta) => ({
        products: response,
        totalCount: meta.response.headers.get('x-total-count'),
      });
    },

    getAllEvents: (endpoint) => {
      endpoint.transformResponse = (response, meta) => ({
        events: response,
        total_count: meta.response.headers.get('x-total-count'),
      });
    },

    getOneTaskPlanner: (endpoint) => {
      endpoint.transformResponse = (response, meta) => ({
        taskPlanner: response,
        encryptedSettings: meta.response.headers.get('encryptedsettings'),
      });
    },

    updateTaskPlanner: (endpoint) => {
      endpoint.transformResponse = (response, meta) => ({
        taskPlanner: response,
        encryptedSettings: meta.response.headers.get('encryptedsettings'),
      });
    },

    createTaskPlanner: (endpoint) => {
      endpoint.transformResponse = (response, meta) => ({
        taskPlanner: response,
        encryptedSettings: meta.response.headers.get('encryptedsettings'),
      });
    },

    getAnalysisPicture: (endpoint) => {
      endpoint.query = null;
      endpoint.queryFn = async (args, _api, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `/analysis/${args.analysisId}/images/${args.imageType}`,
          responseType: 'blob',
          headers: {
            channel: args.channel,
            tenantid: args.tenantid,
            user: args.user,
          },
          responseHandler: (response) => response.blob(),
        });

        const res = {};
        if (response.error) {
          res.error = true;
        } else {
          res.data = window.URL.createObjectURL(response.data);
        }

        return res;
      };
    },

    exportData: (endpoint) => {
      endpoint.query = null;
      endpoint.queryFn = async (args, _api, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `/tools/export`,
          responseType: 'blob',
          headers: {
            tenants: args.tenants,
            collections: args.collections,
          },
          responseHandler: (response) => response.blob(),
        });

        const downloadAnchorEl = document.createElement('a');
        const blob = window.URL.createObjectURL(response.data);
        downloadAnchorEl.href = blob;
        downloadAnchorEl.target = '_blank';
        downloadAnchorEl.download = `export_${new Date().toISOString().replaceAll(':', '.')}.fcabkp`;
        downloadAnchorEl.click();
        downloadAnchorEl.remove();
        window.URL.revokeObjectURL(blob);

        return { data: null };
      };
    },

    getRuleOfTenant: (endpoint) => {
      endpoint.query = null;
      endpoint.queryFn = async (args, _api, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `/tenants/${args.tenantId}/rules/${args.name}`,
          responseType: 'blob',
          responseHandler: (response) => response.blob(),
        });

        const res = {};
        if (response.error) {
          res.error = true;
        } else {
          res.data = window.URL.createObjectURL(response.data);
        }

        return res;
      };
    },

    exportProductsExcel: (endpoint) => {
      endpoint.query = null;
      endpoint.queryFn = async (args, _api, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `/products/export/excel`,
          headers: { tenantId: args.tenantId },
          responseType: 'blob',
          responseHandler: (response) => response.blob(),
        });

        const res = {};
        if (response.error) {
          res.error = response.error;
          res.error.data = await response.error.data.text();
        } else {
          res.data = window.URL.createObjectURL(response.data);
        }

        return res;
      };
    },
    getAnalysisExportFile: (endpoint) => {
      endpoint.query = null;
      endpoint.queryFn = async (args, _api, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `/analysis/export/file/${args.fileName}`,
          headers: { tenantId: args.tenantId },
          responseType: 'blob',
          responseHandler: (response) => response.blob(),
        });

        const res = {};
        if (response.error) {
          res.error = response.error;
          res.error.data = await response.error.data.text();
        } else {
          res.data = window.URL.createObjectURL(response.data);
        }

        return res;
      };
    },
  },
});
